import React from "react"

import { Layout, AboutUs } from "../components"
import Seo from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <Seo
        title="About Web design & web development near me - Vermont Web Development"
        description="Hire me to be your web developer and I’ll help you build a professional website customized to your particular needs."
      />
      <main>
        <AboutUs />
      </main>
    </Layout>
  )
}

export default AboutPage
